<template>
  <v-app>
    <v-main>
      <main-header />
      <main-content class="content" />
    </v-main>
    <main-footer />
  </v-app>
</template>

<script>
import MainContent from './components/Content/index';
import MainFooter from './components/MainFooter';
import MainHeader from './components/MainHeader';

export default {
  components: { MainContent, MainFooter, MainHeader }
};
</script>

<style lang="scss">
@media screen and (min-width: 0) and (max-width: 849px) {
  .content {
    margin: 120px auto 100px;
  }
}

@media screen and (min-width: 850px) and (max-width: 899px) {
  .content {
    margin: 150px auto 100px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1263px) {
  .content {
    margin: 170px auto 120px;
  }
}

@media screen and (min-width: 1264px) {
  .content {
    margin: 180px auto 0;
  }
}
</style>
