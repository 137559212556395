<template>
  <div>
    <v-container class="my-10 d-md-none">
      <v-row no-gutters class="justify-center">
        <v-btn
          :href="formUrl"
          class="black--text px-12 button"
          color="black"
          target="_blank"
          text
          x-large>
          Prijavi se
          <v-icon size="14" class="button-icon ml-3">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
    </v-container>
    <v-container class="my-10 my-md-15 px-4 px-sm-16">
      <v-row>
        <v-col cols="12" sm="6">
          <h1 class="mb-5">
            <strong>Kako će izgledati EE Summer Internship?</strong>
          </h1>
          <p>
            Riječ je o <strong>tromjesečnom</strong> programu, koji započinje
            sredinom srpnja, a tijekom kojeg ćeš imati priliku steći teorijsko
            znanje i praktično iskustvo u različitim područjima razvoja softvera
            i upoznati se s modernim tehnologijama. Mentoriranje od strane naših
            vrhunskih kolega odvijat će se kroz
            <strong>svakodnevnu komunikaciju, pair programming i code review.</strong>
          </p>
          <p>
            Vrijeme rada je u potpunosti
            <strong>fleksibilno (4-8 sati dnevno)</strong>, a učit ćeš iz našeg
            ugodnog <strong>splitskog ureda.</strong> I da, za opremu ne brini
            jer ćemo ti osigurati MacBook Pro računalo. 🙂
          </p>
        </v-col>
        <v-col cols="12" sm="6" class="mt-10 mt-md-0">
          <div class="image-wrapper px-md-5">
            <img class="image image-right" src="/images/9.jpg">
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div
          class="d-flex justify-space-between flex-column flex-md-row my-md-15"
          style="width: 100%;">
          <div class="d-flex flex-column text-center pa-2 testing">
            <span class="pay">7 €/h</span>
            <h4>Plaćeni Internship</h4>
          </div>
          <div class="d-flex flex-column text-center pa-2 testing">
            <v-icon color="#8fc9b8" size="80" class="icon">
              mdi-school-outline
            </v-icon>
            <h4>Mentoriranje 1:1</h4>
          </div>
          <div class="d-flex flex-column text-center pa-2 testing">
            <v-icon color="#6864ad" size="80" class="icon">
              mdi-language-javascript
            </v-icon>
            <h4>Node.js, Vue...</h4>
          </div>
        </div>
      </v-row>
    </v-container>
    <image-grid class="py-5" />
    <v-container class="my-10 my-md-15 px-4 px-sm-16">
      <v-row class="mb-10 mb-md-15">
        <v-col>
          <h1 class="mb-5">
            Tko smo mi i čime se bavimo?
          </h1>
          <p>
            Mi smo servisna firma specijalizirana za razvoj proizvoda i usluga
            iz područja online učenja. Naš tim čini 80 profesionalaca na
            području Hrvatske i Bostona, a svojim djelovanjem želimo pridonijeti
            u stvaranju <strong>novih i revolucionarnih načina učenja.</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-10 mb-md-15">
        <v-col class="mt-10 mt-md-0">
          <h1 class="mb-5">
            <strong>Tko si ti?</strong>
          </h1>
          <p>
            Ambiciozna mlada osoba koja traži priliku kako bi razvila svoje
            developerske vještine. Raduju te izazovi i nove prilike te visoko
            cijeniš vodstvo pod okriljem naših iskusnih developera.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1 class="mb-5">
            Zašto Summer Internship?
          </h1>
        </v-col>
        <v-col cols="12" class="d-flex flex-wrap why-internship">
          <div class="why-internship_box">Znanje i iskustvo</div>
          <div class="why-internship_box">Poznanstva i druženje</div>
          <div class="why-internship_box">Prilika za posao</div>
          <div class="why-internship_box">Džeparac</div>
        </v-col>
      </v-row>
      <v-row class="mb-10 mb-md-15">
        <v-col cols="12" sm="6">
          <p class="font-italic">
            "Iskustvo Internshipa u Extension Engine-u omogućilo mi je da bolje
            upoznam svijet programiranja, da steknem teorijska i praktična
            znanja pod vodstvom iskusnih mentora kao i mnoga korisna poznanstva.
            Što još reći osim da sam ponosna što sam već godinu dana dio EE
            tima." <br>
            -- Stella A.
          </p>
        </v-col>
        <v-col cols="12" sm="6" class="mt-10 mt-md-0">
          <div class="image-wrapper px-md-5">
            <img class="image image-right" src="/images/1.jpg">
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-10 my-md-15 px-4 px-sm-16">
      <v-row>
        <v-col cols="12">
          <h1 class="mb-5">
            <strong>Kako do internshipa?</strong>
          </h1>
          <p class="mb-10">
            Vrlo jednostvano. Samo ispuni prijavu i mi ćemo ti se javiti.
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters class="justify-center">
        <v-btn
          :href="formUrl"
          class="black--text px-12 button"
          color="black"
          target="_blank"
          text
          x-large>
          Prijavi se
          <v-icon size="14" class="button-icon ml-3">mdi-arrow-right</v-icon>
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ImageGrid from './ImageGrid.vue';
export default {
  data() {
    return {
      formUrl: 'https://forms.gle/rD8Hd8DkfX9Rg9mi7'
    };
  },
  components: { ImageGrid }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  z-index: 2;
}

.pay {
  color: #ee8576;
  font-size: 5.5rem;
  font-weight: 500;
  font-family: $font;
}

.testing {
  width: 100%;
}

.text-container {
  max-width: 1280px;

  @include for-small-desktop {
    max-width: 1280px;
  }

  @include for-full-hd {
    max-width: 1024px;
  }
}

.image-wrapper {
  position: relative;
  min-width: 50%;
  overflow: hidden;

  &::before {
    position: absolute;
    top: -65px;
    right: -90%;
    transform: rotate(-175deg);
    width: 200%;
    height: 110px;
    background: #fff;
    z-index: 1;

    @include for-mobile {
      content: " ";
    }
  }

  &::after {
    position: absolute;
    right: -90%;
    bottom: -55px;
    transform: rotate(-175deg);
    width: 200%;
    height: 110px;
    background: #fff;
    z-index: 1;

    @include for-mobile {
      content: " ";
    }
  }

  @include for-mobile {
    min-height: 500px;
    margin: -17px;
  }
}

.image {
  max-width: 100%;
  min-height: 500px;
  object-fit: cover;
}

.label {
  font-size: 24px;
  font-weight: 500;
}

.button {
  padding: 3px 5px;
  border: 1px solid black;
  border-radius: 0;
}

.intro {
  position: relative;
  z-index: 1000;
}

p,
a {
  font-size: 2rem;

  @include for-large-desktop {
    font-size: 2.2rem;
  }
}

.faq strong {
  font-size: 2.3rem;
}

::v-deep .v-btn.v-size--x-large {
  font-size: 1.4rem;
}

.why-internship {
  flex-direction: column;

  @media (min-width: 900px) {
    flex-direction: row;
  }
}

.why-internship_box {
  padding: 1.5rem;
  border: 2px solid #ee8577;
  border-radius: 20px;
  margin-bottom: 2rem;
  width: fit-content;

  @media (min-width: 900px) {
    margin-right: 2rem;
  }
}
</style>
