<template>
  <header class="header text-center">
    <div
      ref="backgroundImage"
      class="background-header"
      data-lax-scale="0 1, 600 1.1">
      <div class="heading pa-3">
        <div class="mx-3 text-left">
          <a href="https://extensionengine.com" target="_blank" class="d-flex">
            <img
              ref="logo"
              src="../assets/images/logo.svg"
              alt="Extension Engine"
              class="logo">
          </a>
        </div>
        <h1>Summer Internship</h1>
        <div class="d-none d-md-flex justify-center">
          <v-btn
            href="https://forms.gle/rD8Hd8DkfX9Rg9mi7"
            class="black--text px-12 button"
            color="black"
            target="_blank"
            text
            x-large>
            Prijavi se
            <v-icon size="14" class="button-icon ml-3">mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div
      ref="frontGraphic"
      class="front-graphic"
      data-lax-translate-y="0 100, 600 0"></div>
  </header>
</template>

<script>
import lax from 'lax.js';

export default {
  created() {
    lax.setup();
    document.addEventListener(
      'scroll',
      () => {
        lax.update(window.scrollY);
      },
      false
    );
  },
  mounted() {
    lax.addElement(this.$refs.frontGraphic);
    lax.addElement(this.$refs.backgroundImage);
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-btn.v-size--x-large {
  position: relative;
  font-size: 1.6rem;
  z-index: 20;
}

.button {
  margin-top: 20px;
  padding: 3px 5px;
  border: 1px solid black;
  border-radius: 0;
}

.header {
  position: relative;

  .logo {
    width: 90px;
  }

  .background-header {
    width: 100%;
    height: 350px;
    background-image: url("../assets/images/background.svg"),
      linear-gradient(transparent 60%, #fae7bb 50%);
    background-size: cover;
    background-position: right;
  }

  .front-graphic {
    position: absolute;
    top: 85px;
    width: 100%;
    height: 385px;
    background-image: url("../assets/images/front-graphic.svg"),
      linear-gradient(transparent 60%, #fff 50%);
    background-size: cover;
    background-position: center;
  }

  .heading {
    width: 100%;
  }

  h1 {
    margin: 1rem 0 0 0;
  }
}

$background-image-aspect-ratio: 2.17;
$front-graphic-aspect-ratio: 2.46;

@media screen and (min-width: 600px) and (max-width: 959px) {
  .header {
    .logo {
      width: 110px;
    }

    .background-header {
      height: 390px;
    }

    .front-graphic {
      top: 80px;
      height: calc(100vw / #{$front-graphic-aspect-ratio});
    }

    .heading {
      h1 {
        font-size: 60px;
        line-height: 60px;
      }

      h2 {
        font-size: 34px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .header {
    .logo {
      width: 110px;
    }

    .background-header {
      height: 550px;
    }

    .front-graphic {
      top: 160px;
      height: calc(100vw / #{$front-graphic-aspect-ratio});
      background-position: unset;
    }

    .heading {
      h1 {
        font-size: 84px;
        line-height: 90px;
      }

      h2 {
        font-size: 50px;
        line-height: 54px;
      }
    }
  }
}

@media screen and (min-width: 1264px) {
  .header {
    .background-header {
      height: calc(100vw / #{$background-image-aspect-ratio} + 20px);
    }

    .front-graphic {
      top: 220px;
    }

    .heading h1 {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 599px) {
  .header {
    .front-graphic {
      top: 80px;
      height: 300px;
    }
  }
}
</style>
