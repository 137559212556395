import hr from 'vuetify/es5/locale/hr';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#26A0E4',
        secondary: '#ffc107',
        paleSecondary: '#fffbf0',
        accent: '#1C7F9B',
        error: '#FF5252',
        info: '#BFE5FA',
        success: '#4CAF50',
        warning: '#FFC107',
        text: '#424242'
      }
    }
  },
  lang: {
    locales: { hr },
    current: 'hr'
  }
});
