<template>
  <v-container fluid>
    <v-row v-if="isMobile" class="slider">
      <v-carousel hide-delimiters>
        <v-carousel-item
          v-for="img in images"
          :key="`carousel-${img}`"
          :src="`./images/${img}.jpg`" />
      </v-carousel>
    </v-row>
    <v-row v-else>
      <v-img src="/images/horizontal.jpg" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'image-grid',
  data: () => ({
    isMobile: false,
    images: [4, 5, 6]
  }),
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
    }
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return;
    window.removeEventListener('resize', this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  }
};
</script>

<style lang="scss" scoped>
.slider {
  overflow: hidden;
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: -85px;
    right: -90%;
    transform: rotate(177deg);
    width: 200%;
    height: 100px;
    background: #fff;
    z-index: 1;

    @include for-mobile {
      transform: rotate(175deg);
    }
  }

  &::after {
    content: " ";
    position: absolute;
    right: -90%;
    bottom: -12px;
    transform: rotate(177deg);
    width: 200%;
    height: 100px;
    background: #fff;
    z-index: 1;
    @include for-mobile {
      transform: rotate(175deg);
    }
  }
}
</style>
