<template>
  <footer>
    <nav>
      <v-container>
        <v-row justify="space-between" class="text-uppercase">
          <v-col cols="12" md="3">
            <ul id="menu-footer" class="footer-list">
              <li><a href="mailto:internship@extensionengine.com">Contact</a></li>
              <li><a target="_blank" rel="noopener" href="https://blog.extensionengine.com/">Blog</a></li>
              <li><a target="_blank" rel="noopener" href="https://extensionengine.com/careers/">Careers</a></li>
            </ul>
          </v-col>
          <v-col cols="12" md="auto">
            <ul class="footer-list social">
              <li v-for="link in links" :key="link.href">
                <a
                  :href="link.href" target="_blank" rel="noopener"><v-icon>{{ link.icon }}</v-icon>
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </nav>
    <div class="black-bg">
      <v-container>
        <v-row class="justify-space-between text-uppercase">
          <a href="https://extensionengine.com/terms-and-conditions/">Terms &amp; Conditions</a>
          <span>2022 © All Rights Reserved</span>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
export default {
  data: () => ({
    links: [{
      href: 'https://github.com/ExtensionEngine',
      icon: 'mdi-github'
    }, {
      href: 'https://www.facebook.com/ExtensionEngineCroatia',
      icon: 'mdi-facebook'
    }, {
      href: 'https://www.instagram.com/extension_engine_croatia',
      icon: 'mdi-instagram'
    }]
  })
};
</script>

<style lang="scss" scoped>
footer {
  font-family: $font;
  font-size: 1.2rem;
  letter-spacing: 0.15em;
  font-weight: 500;
  text-transform: uppercase;
  border-top: 1px solid #f1f1f1;

  ul {
    @include zeroList;
    @include flexbox;

    a {
      display: block;
      padding: 3.5rem 3rem;
      color: #a2aaad;
      font-size: 1.2rem;
      font-weight: 500;
      text-decoration: none;
    }

    &.social {
      a {
        padding: 3.5rem 1rem;
        &:hover { opacity: 0.6; }
      }
    }
  }

  > div {
    padding: 1rem 0;
    color: #a2aaad;

    img {
      display: inline-block;
      position: relative;
      top: 3px;
    }

    a {
      text-decoration: none;
      color: #a2aaad;
    }
  }

  .black-bg {
    background: #000;
  }

  @include for-mobile {
    nav {
      padding: 2rem 0 0.5rem;
      text-align: center;

      .row {
        flex-direction: column-reverse;
      }

      ul.footer-list {
        display: flex;
        @include justify-content(center);

        a {
          padding: 1rem;
        }
      }
    }

    .black-bg .row {
      @include row-rest;

      text-align: center;
      padding: 0.75rem 0;

      > * {
        padding: 1rem 0;
        &:first-child { order: 2; }
        &:nth-child(2) { order: 3; }
        &:last-child { order: 1; }
      }
    }
  }
}
</style>
